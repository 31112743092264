import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function StatutoryNotices() {
    const { graphCmsContact } = useStaticQuery(graphql`
        {
            graphCmsContact(name: {eq: "Cultive"}) {
                email
                telephone
                hebergeur
                respnsable
            }
        }
    `)

    return (
        <div>
            <div style={{ position: "relative", alignItems: "center", padding: "10vh 8vw 15px" }}>
                <h1>Mentions Légales</h1>
                <h2>Hébergeur</h2>
                <p>{graphCmsContact.hebergeur}</p>
                <h2>Responsable de Publication et Editeur</h2>
                <p>{graphCmsContact.responsable}</p>
                <h2>Contact</h2>
                <p>{graphCmsContact.email}</p>
                <p>{graphCmsContact.telephone}</p>
            </div>
        </div>
    )
}
